<script>
    import { _ } from '../services/i18n';
    import { add } from "ol/coordinate";
    import {myvehicles, mytoolz} from '../myfields_store.js'
    import { getContext } from 'svelte';
    //import {initTractorMarker} from '../Map.svelte'
    import {sveltemap_global} from '../myfields_store.js'
    let api_host = 'https://tractorpilot.com'
     if (location.host.includes("localhost"))  api_host = 'http://tp.localhost'
    let addName="";
    let addId="";
    let addType = 0;
    let editId = 0;
    let type2veh = {1:$_("vehicle_type_tractor"),2:$_("vehicle_type_harvester") }

    function test(vid) {
        //alert ($sveltemap_global);
        //$sveltemap_global.initTractorMarkerMap(vid);
    }
    //alert (sveltemap_global.initTractorMarkerMap())
    //const initTractorMarkerMap = getContext('initTractorMarkerCtx');
    //if (initTractorMarkerMap==='function') {
        //alert ('FUNX');
    //}
    //else {
       // alert (initTractorMarkerMap);
    //}

    async function addVehicle() {
        let formData = new FormData();
        formData.append('vName', addName);
        formData.append('vId', addId); 
        formData.append('vType', addType);
        formData.append('editId',editId);
       let addVehicleReq = await fetch(api_host+"/Vehicles/addVehicle/"+localStorage.getItem('client_code'),
       { method: 'POST',
         body: formData   
        }
       );
       let addVehicleResp = await addVehicleReq.json();
       addName = "";
       addId = "";
       addType = 0;
       editId = 0;
       getMyVehicles();
    }

    async function getMyVehicles() {
       let myVehiclesReq = await fetch(api_host+"/Vehicles/getUserVehicles/"+localStorage.getItem('client_code'));
       let myVehiclez = await myVehiclesReq.json();
  
       myvehicles.set(myVehiclez)
    }

    async function getMyTools() {
       let req = await fetch(api_host+"/Vehicles/getImplements/"+localStorage.getItem('client_code'));
       let myTools = await req.json();
       mytoolz.set(myTools)
    }

    async function delVehicle(entryId) {
    //alert (vehId);    
    let req = await fetch(api_host+"/Vehicles/delVehicle/"+localStorage.getItem('client_code')+"/"+entryId);
    let resp = await req.json();
       getMyVehicles();
    }

    async function delTool(toolId) {
        let req = await fetch(api_host+"/Vehicles/delTool/"+localStorage.getItem('client_code')+"/"+toolId);
        let resp = await req.json();
        getMyTools();
    }

    getMyVehicles();
    getMyTools();

    function initEdit(type,id,name,edId) {
        addType = type;
        addId = id;
        addName = name;
        editId = edId;
    }

</script>
<div class="container-fluid paper-wrap bevel tlbr">

    <!-- CONTENT -->
    <!--TITLE -->
    <div class="row">
        <div id="paper-top">
            <div class="col-sm-3">
                <h2 class="tittle-content-header">
                    <i class="icon-location"></i> 
                    <span>{$_("vehicles_management")}
                    </span>
                </h2>

            </div>
        </div>
    </div>


    <div class="nest" id="tableStaticClose">
        <div class="title-alt">
            <h6>
                {$_("my_vehicles")}</h6>
            <div class="titleClose">
                <a class="gone" href="#tableStaticClose">
                    <span class="entypo-cancel"></span>
                </a>
            </div>
            <div class="titleToggle">
                <a class="nav-toggle-alt" href="#tableStatic">
                    <span class="entypo-up-open"></span>
                </a>
            </div>

        </div>

        <div class="body-nest" id="tableStatic">

            <section id="flip-scroll">

                <table class="table table-bordered table-striped cf">
                    <thead class="cf">
                        <tr>
                            <th>{$_("vehicle_type")}</th>
                            <th>{$_("vehicle_id")}</th>
                            <th>{$_("vehicle_name")}</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>
                    <tbody>
                        {#each $myvehicles as fld, i}
                        <tr>
                            <td>{type2veh[fld.vType]}</td>
                            <td>{fld.vId}</td>
                            <td>{fld.vName}</td>
               
                            <td>
                                <a on:click={delVehicle(fld.id)} style="cursor:pointer ;">{$_("delete")}</a> |
                                <a on:click={initEdit(fld.vType,fld.vId,fld.vName,fld.id)} style="cursor:pointer">{$_("edit")}</a>
                            </td>
                        </tr> 
                        {/each}
                    </tbody>
                </table>
            </section>

        </div>

    </div>




  

            <div class="body-nest" id="validation">
                <div class="form_center">

                    <form class="form-horizontal">
                        <fieldset>
                            <div class="control-group">
                                <label class="control-label" for="name">{$_("vehicle_type")}</label>
                                <div class="controls">
                                    <select bind:value="{addType}" class="form-control">
                                        <option value=1>{$_("vehicle_type_tractor")}</option>
                                        <option value=2>{$_("vehicle_type_harvester")}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="control-group">
                                <label class="control-label" for="name">{$_("vehicle_id")}</label>
                                <div class="controls">
                                    <input type="text" class="form-control" bind:value="{addId}">
                                </div>
                            </div>
                            <div class="control-group">
                                <label class="control-label" for="email">{$_("vehicle_name")}</label>
                                <div class="controls">
                                    <input type="text" class="form-control" bind:value={addName}>
                                </div>
                            </div>
                   
                            <div class="form-actions" style="margin:20px 0 0 0;">
                                <a class="btn btn-primary" on:click="{addVehicle}">{$_("Save")}</a>
                            </div>
                        </fieldset>
                    </form>

                </div>
            </div>

     
          
            








            <div class="nest" id="tableStaticClose">
                <div class="title-alt">
                    <h6>
                        {$_("my implements")}</h6>
                    <div class="titleClose">
                        <a class="gone" href="#tableStaticClose">
                            <span class="entypo-cancel"></span>
                        </a>
                    </div>
                    <div class="titleToggle">
                        <a class="nav-toggle-alt" href="#tableStatic">
                            <span class="entypo-up-open"></span>
                        </a>
                    </div>
        
                </div>
        
                <div class="body-nest" id="tableStatic">
        
                    <section id="flip-scroll">
        
                        <table class="table table-bordered table-striped cf">
                            <thead class="cf">
                                <tr>
                                    <th>{$_("Tool Name")}</th>
                                    <th>{$_("Tool Id")}</th>
                                    <th>&nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                {#each $mytoolz as tool, i}
                                <tr>
                                    <td>{tool.toolName}</td>
                                    <td>{tool.toolId}</td>
                       
                                    <td>
                                        <a on:click={delTool(tool.toolId)} style="cursor:pointer ;">{$_("delete")}</a>
                                    </td>
                                </tr> 
                                {/each}
                            </tbody>
                        </table>
                    </section>
        
                </div>
        
            </div>







</div>    