<script>
      import { _ } from '../services/i18n';
      import { setupI18n } from '../services/i18n';
      //import {myisobuslogs} from '../myfields_store.js'
      import {myisobuslogs} from '../myfields_store.js'
      let api_host = 'https://tractorpilot.com'
     if (location.host.includes("localhost"))  api_host = 'http://tp.localhost'
      let selected_lang = localStorage.getItem('userLang');
      const changeLang = () => {
          if (selected_lang!="")
          {
            localStorage.setItem('userLang',selected_lang);
            location.reload();
          }
	}

    let privKey = "";
    let pubKey = "";
    let cliPubKey = "";

    async function getIsobusLogs() {
       let req = await fetch(api_host+"/Filer/getUserLogs/"+localStorage.getItem('client_code'));
       let data = await req.json();
        
       myisobuslogs.set(data)
    }

    async function genKeys() {
        let req = await fetch(api_host+"/Users/genKeyPair");
        let data = await req.json();
        privKey = data['private'];
        pubKey = data['public'];
    }

    async function getKeys() {
        let req = await fetch(api_host+"/Users/getCurveKeys/"+localStorage.getItem('client_code')); 
        let data = await req.json();
        privKey = data['private'];
        pubKey = data['public']; 
        cliPubKey = data['cli_public']
    }

    async function saveKeys() {
        let formData = new FormData();
        formData.append('privKey', privKey);
        formData.append('pubKey', pubKey); 
        formData.append('cliPubKey', cliPubKey);

        let req = await fetch(api_host+"/Users/saveCurveKeys/"+localStorage.getItem('client_code'),
        { method: 'POST',
         body: formData   
        }
    ); 
        
    }

    getKeys();
    getIsobusLogs();

</script>
<div class="container-fluid paper-wrap bevel tlbr">

    <!-- CONTENT -->
    <!--TITLE -->
    <div class="row">
        <div id="paper-top">
            <div class="col-sm-3">
                <h2 class="tittle-content-header">
                    <i class="icon-location"></i> 
                    <span>{$_('settings')}.
                    </span>
                </h2>

            </div>

    
         
        </div>
    </div>
    <!--/ TITLE -->



<div class="content-wrap">
    <div class="row">


        <div class="col-sm-12">
       
            <h3>{$_("lang")}</h3>
                <select bind:value={selected_lang} on:change={changeLang}>
                    <option value=""></option>
                    <option value="en">English</option>
                    <option value="ru">Russian</option>
                </select>

        </div>

    

                </div>

                </div>

                <div class="col-sm-6">    
                    <div>
                        <h3>SYNC KEYS</h3>
                        <ul>
                            <li><h4>Sync Server Secret Key: <b>{privKey}</b></h4></li>
                            <li><h4>Sync Server Public Key: <b>{pubKey}</b></h4></li>
                        </ul>
                        <div class="form-actions" style="margin:20px 0 0 0;">
                            <a class="btn btn-primary" on:click={genKeys}>{$_("Generate New Keys")}</a>
                            <a class="btn btn-primary" on:click={saveKeys}>{$_("Save New Keys")}</a>
                        </div>
                        <ul>
                            <li><h4>TP Client Public Key: <strong>{cliPubKey}</strong></h4></li>
                        </ul>    
                   
                     </div>
                 </div>
                 
                 
                {#if $myisobuslogs && $myisobuslogs.length > 0}
                <div class="col-sm-12">    
                <div>
                    <h3>ISOBUS logs</h3>

                    <div class="body-nest" id="tableStatic">

                        <section id="flip-scroll">
            
                            <table class="table table-bordered table-striped cf">
                                <thead class="cf">
                                    <tr>
                                        <th>LOG file</th>
                                        <th></th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {#each $myisobuslogs as isolog, i}
                        <tr>
                            <td><a href="{api_host}/files/logs/{localStorage.getItem('client_code')}/{isolog.log_name}">{isolog.log_name}</a></td>
                            <td>{isolog.size} kb</td>
                            <td>&nbsp;</td>
                            </tr>

                                {/each}
                                </tbody>
                            </table>
                        </section>
            
                    </div>

                </div>
                </div>
                {/if}

                
            </div>