<span
  bind:this={element}
  use:useActions={use}
  use:forwardEvents
  {...$$restProps}><slot /></span
>

<script>import { get_current_component } from 'svelte/internal';
import { forwardEventsBuilder, useActions } from '../internal/index.js';
// Remember to update types file if you add/remove/rename props.
export let use = [];
const forwardEvents = forwardEventsBuilder(get_current_component());
let element;
export function getElement() {
    return element;
}
</script>
