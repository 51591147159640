<script>
    import VRA_maps from './VRA_maps.svelte';
    import Load_kml from './Load_kml.svelte';
    import Vehicles from './Vehicles.svelte';
    import Tasks from './Tasks.svelte';
    import Settings from './Settings.svelte';
    import Reports from './Reports.svelte';
    import FieldManagementP from './FieldManagementP.svelte';
    import {myfields, user_loged_in} from '../myfields_store.js'
    import {active_page2} from '../login_store.js'
    export let active_page;

</script>
<div class="wrap-fluid">
{#if active_page=='/#vehicles'}
<Vehicles/>
{/if}

{#if active_page=='/reports'}
<Reports/>
{/if}

{#if active_page=='/#settings'}
<Settings/>
{/if}

{#if active_page=='/#vra_maps'}
<VRA_maps/>
{/if}

{#if active_page=='/tasks'}
<Tasks/>
{/if}

{#if active_page=='/#load_kml'}
<Load_kml/>
{/if}

{#if active_page=='/#fields' || active_page=='/' }
<FieldManagementP/>
{/if}



</div>